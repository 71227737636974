<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
    >
      <div class="d-flex align-items-center mb-4 mt-4">
        <div class="h3">
          Список страниц
        </div>

        <b-button
          v-if="showNewPageButton"
          variant="primary"
          class="ml-auto"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          @click="() => openPageEditModal()"
        >
          Добавить новую страницу
        </b-button>
      </div>

      <div
        v-if="!totalCount && !isLoading"
        class="px-3"
      >
        Ничего не найдено
      </div>

      <template v-if="isLoading">
        <preloader style="margin-top: 20px" />
      </template>

      <b-table
        v-if="totalCount && !isLoading"
        class="bg-white pages-table"
        bordered
        :fields="fields"
        :items="pages"
      >
        <template #cell(pageId)="data">
          <span v-if="data.value">
            {{ data.value.name }} - {{ data.value.url }}
          </span>
        </template>

        <template #cell(isMenuItem)="data">
          <span
            v-if="data.item.isMenuItem"
            class="text-success"
          >
            Доступно
          </span>
          <span
            v-else
            class="text-danger"
          >
            Скрыто
          </span>
        </template>

        <template #cell(Действия)="data">
          <b-button
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openPageEditModal(data.item.id)"
          >
            <b-icon icon="pencil" />
          </b-button>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'Pages',
  page: {
    title: 'CRM Doctis - Список страниц',
  },
  components: {
    Preloader,
  },
  data() {
    return {
      fields: [
        {
          key: 'index',
          label: 'Индекс',
        },
        {
          key: 'name',
          label: 'Название',
          class: 'break-words',
        },
        {
          key: 'url',
          label: 'Адрес',
          class: 'break-words',
        },
        {
          key: 'pageId',
          label: 'Родительская страница',
          class: 'break-words',
          formatter: (value) => this.pages.find((p) => p.id === value),
        },
        {
          key: 'isMenuItem',
          label: 'Меню',
        },
        {
          key: 'Действия',
          label: '',
          class: 'td-button',
        },
      ],
    };
  },
  computed: {
    showNewPageButton() {
      return !!['dev', 'localhost', 'kd-1000'].find((host) => window.location.host.includes(host));
    },
    pages() {
      const { pages } = this.$store.state.Pages;
      return pages.sort((a, b) => a.index - b.index);
    },
    totalCount() {
      return this.pages.length;
    },
    isLoading: {
      get() {
        return this.$store.state.Pages.isPagesLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.PAGES_LOADING_SET, newVal);
      },
    },
  },
  async mounted() {
    await this.pagesFetch();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.Pages_SET, []);
    this.isLoading = false;
  },
  methods: {
    async pagesFetch() {
      this.isLoading = true;
      try {
        const roles = await this.$store.dispatch(this.$types.PAGES_FETCH);
        this.$store.commit(this.$types.PAGES_SET, roles);
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    openPageEditModal(pageId) {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'PageEditModal', props: { pageId } });
    },
  },
};
</script>

<style lang="scss" scoped>
  // ::v-deep.bg-white .td-button {
    // width: 100px;
  // }

  ::v-deep.pages-table th, ::v-deep.pages-table td {
    word-break: keep-all;
  }

  ::v-deep.pages-table .break-words {
    word-break: break-word;
  }
</style>
